import axios from 'axios';
import Ferramentas from '../classes/ferramentas';
import imgLogo from '../assets/img/brand/simbolo_lateral.png';

export default class FirebaseService {
    static getVersion = () => {
        return '/api/v2';
    }


    static getToken = () => {
        return "Bearer "+localStorage.getItem('token');
    }

    static getHeader = () => {
        return { headers: { Authorization: this.getToken(), timeout:10000 } };
    }

    static getHostServer = () => {
        let retorno = '';
        let rawdata = window.location.hostname;
        if (rawdata.indexOf('localhost') > -1)
        {
            retorno = 'http://localhost:3000';
        }
        retorno = "https://gohair.app";
        return retorno; 
    };

    static GetLogoURL = () => {      
        let retorno = "";  
        const slug = Ferramentas.Slug();
        if (slug){
            retorno = this.getHostServer()+this.getVersion()+'/estabelecimentos/logo/'+slug+'?v='+Math.random(); 
        } else {
            retorno = imgLogo; 
        }    
        return retorno;     
    }; 

    static getStatus = async (callback, callbackError) => {
        let url = this.getHostServer()+this.getVersion()+'/status';
        axios.get(url, this.getHeader())
        .then(function(response){            
            let newData = {};
            if ((response.status === 200) && (response.data.status.toString() === "0")){
                newData = response.data.retorno;            
                callback(newData);
                return;
            } 
            throw new Error('Status invalido: '+response.status);
        })
        .catch(function (error) {
            console.log(error);
            callbackError(error);
        });  
    }

    static getValidAutentic = async (slug, callback, callbackError) => {
        let url = this.getHostServer()+this.getVersion()+'/validar/'+slug;
        axios.get(url, this.getHeader())
        .then(function(response){            
            let newData = {};
            if ((response.status === 200) && (response.data.status.toString() === "0")){
                newData = response.data.retorno;            
                callback(newData);
                return;
            } 
            throw new Error('Status invalido: '+response.status);
        })
        .catch(function (error) {
            console.log(error);
            callbackError(error);
        });  
    }

    static getValidExists = async (slug, callback, callbackError) => {
        let url = this.getHostServer()+this.getVersion()+'/validar/Existe/'+slug;
        axios.get(url, this.getHeader())
        .then(function(response){            
            let newData = {};
            if ((response.status === 200) && (response.data.status.toString() === "0")){
                newData = response.data.retorno;            
                callback(newData);
                return;
            } 
            throw new Error('Status invalido: '+response.status);
        })
        .catch(function (error) {
            console.log(error);
            callbackError(error);
        });  
    }    

    // localhost:3000/salaovip/clientes/pesquisa/58798/ab
    static getDataListClientes = async (search, callback, callbackError, size = 10) => 
    {
        let url = this.getHostServer()+this.getVersion()+'/clientes/'+localStorage.getItem('salao_id')+'/pesquisa/'+search;
        //let url  = 'https://api.salaovip.com.br/salao/'+localStorage.getItem('salao_id')+'/cliente/?chave=nome&valor='+search;
        axios.get(url, this.getHeader())
        .then(function(response){            
            let newData = [];
            if (response.data.status.toString() === '0'){
                newData = response.data.retorno;
            } else {
                throw response.data.retorno.message; 
            }
            callback(newData);
        })
        .catch(function (error) {
            console.log(error);
            callbackError(error);
        });   
    };

    //localhost:3000/salaovip/clientes/58798/56082690
    static getDataCliente = async (id, callback) => 
    {
        let url = this.getHostServer()+this.getVersion()+'/clientes/'+localStorage.getItem('salao_id')+'/'+id;
        //let url  = 'https://api.salaovip.com.br/salao/'+localStorage.getItem('salao_id')+'/cliente/'+id;
        axios.get(url, this.getHeader())
        .then(function(response){            
            let newData = [];
            if (response.data.status.toString() === '0'){
                newData = response.data.retorno[0];
            } else {
                throw response.data.retorno.message; 
            }
            callback(newData);
        })
        .catch(function (error) {
            console.log(error);
        });  
    };

    static validar = (objToSubmit, callback) => {
        let url  = this.getHostServer()+this.getVersion()+'/usuarios/token';
        axios.post(url,{email:objToSubmit.email, senha:objToSubmit.senha, slug:objToSubmit.slug}, this.getHeader())
        .then(function(response){            
            let newData = [];
            if (response.data.status.toString() === '0'){
                newData = response.data.retorno;
            }
            callback(newData);
        })     
        .catch(function (error) {
            console.log(error);
        });  
    }

    static Unidades = async (id, callback) => 
    {
        //let url  = this.getHostServer()+this.getVersion()+'/salaovip/unidades/profissional/'+id;
        let url  = this.getHostServer()+this.getVersion()+'/estabelecimentos/unidades/email/'+id;
        axios.get(url,{timeout:10000})
        .then(function(response){            
            let newData = [];
            if (response.data.status.toString() === '0'){
                newData = response.data.retorno;
            }
            callback(newData);
        })     
        .catch(function (error) {
            console.log(error);
            callback(error);
        });  
    };

    static TodasAnalises = async (id, callback) => 
    {
        let url  = this.getHostServer()+this.getVersion()+'/analise/todas/'+localStorage.getItem('salao_id')+'/'+id;
        axios.get(url, this.getHeader())
        .then(function(response){            
            let newData = [];
            if (response.data.status.toString() === '0'){
                newData = response.data.retorno;
            }
            callback(newData);
        });     
    };

    static AnaliseUnico = async (cliente, id, callback) => 
    {
        let url  = this.getHostServer()+this.getVersion()+'/analise/'+cliente+'/'+id;
        axios.get(url, this.getHeader())
        .then(function(response){            
            let newData = [];
            if (response.data.status.toString() === '0'){
                newData = response.data.retorno;
            }
            callback(newData);
        });     
    };

    static AnaliseNova = async (payload, callback) => 
    {
        let url  = this.getHostServer()+this.getVersion()+'/analise/nova';
        axios.post(url,payload, this.getHeader())
        .then(function(response){            
            let newData = [];
            if (response.data.status.toString() === '0'){
                newData = response.data.retorno;
            }
            callback(newData);
        });     
    };

    static AnaliseAltera = async (payload, callback) => 
    {
        let url  = this.getHostServer()+this.getVersion()+'/analise/altera/'+payload.cliente+'/'+payload.id;
        axios.post(url,payload, this.getHeader())
        .then(function(response){            
            let newData = [];
            if (response.data.status.toString() === '0'){
                newData = response.data.retorno;
            }
            callback(newData);
        });     
    };

    static AnaliseEtapa = async (payload, callback) => 
    {
        var bodyFormData = new FormData();
        bodyFormData.set('foto', null);
        bodyFormData.set('cliente',payload.cliente);
        bodyFormData.set('tipo', payload.tipo);
        bodyFormData.set('etapa', payload.etapa);
        bodyFormData.set('responsavel', payload.responsavel);
        bodyFormData.set('atendimento_id', payload.atendimento_id);
        let item;
        for (item in Object.keys(payload)) {
            let k = Object.keys(payload)[item];
            let v = payload[Object.keys(payload)[item]];
            if (bodyFormData.get(k) == null){
                bodyFormData.set(k, v);
            }
        }
        axios(
            {
                method: 'post',
                url:  this.getHostServer()+this.getVersion()+'/analise/etapa',
                data: bodyFormData,
                headers: {'Content-Type': 'multipart/form-data', Authorization: this.getToken() }
                }
        )
        .then(function(response){            
            let newData = [];
            if (response.data.status.toString() === '0'){
                newData = response.data.retorno;
            }
            callback(newData);
        });     
    };

    static TodasEtapas = async (cliente, id, callback) => 
    {
        let url  = this.getHostServer()+this.getVersion()+'/analise/etapa/todas/'+cliente+'/'+id;
        axios.get(url, this.getHeader())
        .then(function(response){            
            let newData = [];
            let hash = '';
            if (response.data.status.toString() === '0'){
                newData = response.data.retorno;
                hash = response.data.hash;
            }
            callback(newData,hash);
        });     
    };

    static ApagarTipoEtapa = async (analise, etapa, tipo, callback) => 
    {
        let url  = this.getHostServer()+this.getVersion()+'/analise/etapa/apaga/'+analise+'/'+etapa+'/'+tipo;
        axios.post(url,{}, this.getHeader())
        .then(function(response){            
            let newData = [];
            if (response.data.status.toString() === '0'){
                newData = response.data.retorno;
            }
            callback(newData);
        });     
    }; 

    static RelatorioComparacao = async (cliente, de, ate, callback) => 
    {
        let url  = this.getHostServer()+this.getVersion()+'/analise/relatorios/comparacao/'+cliente+'/'+de+'/'+ate;
        axios.get(url, this.getHeader())
        .then(function(response){            
            let newData = [];
            let hash = '';
            if (response.data.status.toString() === '0'){
                newData = response.data.retorno;
                hash = response.data.hash;
            }
            callback(newData,hash);
        });     
    };

    static RelatorioProgresso = async (cliente, de, ate, callback) => 
    {
        let url  = this.getHostServer()+this.getVersion()+'/analise/relatorios/progresso/'+cliente+'/'+de+'/'+ate;
        axios.get(url, this.getHeader())
        .then(function(response){            
            let newData = [];
            let hash = '';
            if (response.data.status.toString() === '0'){
                newData = response.data.retorno;
                hash = response.data.hash;
            }
            callback(newData,hash);
        });     
    };

    static NovoUsuario = async (payload, callback) => {        
        let url  = this.getHostServer()+this.getVersion()+'/usuarios/novo';
        axios.post(url,payload, this.getHeader())
        .then(function(response){ 
            callback(response.data);
        });   
    }

    static NovoCliente = async (payload, callback) => {        
        let url  = this.getHostServer()+this.getVersion()+'/clientes';
        axios.post(url,payload, this.getHeader())
        .then(function(response){ 
            callback(response.data);
        })
        .catch(function (error) {
            console.log(error);
            callback(error);
        });           
    }

    static EditarCliente = async (payload, callback) => {        
        let url  = this.getHostServer()+this.getVersion()+'/clientes';
        axios.put(url,payload, this.getHeader())
        .then(function(response){ 
            callback(response.data);
        });   
    }

    static DeletarCliente = async (cliente, callback) => {        
        let url  = this.getHostServer()+this.getVersion()+'/clientes/'+cliente;
        axios.delete(url,this.getHeader())
        .then(function(response){ 
            callback(response.data);
        })     
        .catch(function (error) {
            console.log(error);
            callback(error);
        });   
    }

    static AnaminesePerguntas = async (callback) => 
    {
        let url  = this.getHostServer()+this.getVersion()+'/anaminese';
        axios.get(url, this.getHeader())
        .then(function(response){            
            let newData = [];            
            if (response.data.status.toString() === '0'){
                newData = response.data.retorno;
            }
            callback(newData);
        });     
    };

    static GravarAnaminese = async (payload, callback) => 
    {
        let url  = this.getHostServer()+this.getVersion()+'/anaminese';
        axios.post(url,payload, this.getHeader())
        .then(function(response){            
            callback(response.data);
        });     
    };

    static AnamineseRespostas = async (id, callback) => 
    {
        let url  = this.getHostServer()+this.getVersion()+'/anaminese/'+id;
        axios.get(url, this.getHeader())
        .then(function(response){            
            let newData = [];            
            if (response.data.status.toString() === '0'){
                newData = response.data.retorno;
            }
            callback(newData);
        });     
    };

    static Aprovar = async (hash, callback) => {        
        const url  = this.getHostServer()+this.getVersion()+'/usuarios/aprovar/'+hash;
        axios.get(url, this.getHeader())
        .then(function(response){            
            callback(response.data.status.toString(),response.data.retorno);
        })     
        .catch(function (error) {
            console.log(error);
            callback("-1",error.toString());
        });  
    };

    static TrocarSenha = async (payload, callback) => {
        let url  = this.getHostServer()+this.getVersion()+'/usuarios/senha/trocar';
        axios.post(url,payload, this.getHeader())
        .then(function(response){                  
            callback(response.data);
        })     
        .catch(function (error) {
            console.log(error);
        });  
    };

    static ReenviarSenha = async (payload, callback) => {
        let url  = this.getHostServer()+this.getVersion()+'/usuarios/reenviar/senha';
        axios.post(url,payload, this.getHeader())
        .then(function(response){                  
            callback(response.data);
        })     
        .catch(function (error) {
            console.log(error);
        });  
    };

    static GetUF = async (callback) => {        
        const url  = this.getHostServer()+this.getVersion()+'/utilitarios/uf';
        axios.get(url, this.getHeader())
        .then(function(response){            
            callback(response.data.status.toString(),response.data.retorno);
        })     
        .catch(function (error) {
            console.log(error);
            callback("-1",error.toString());
        });  
    };
    
    static GetAnamineseVisualizacao = async (id, callback) => {        
        const url  = this.getHostServer()+this.getVersion()+'/anaminese/visualizar/'+id;
        axios.get(url, this.getHeader())
        .then(function(response){            
            callback(response.data.status.toString(),response.data.retorno);
        })     
        .catch(function (error) {
            console.log(error);
            callback("-1",error.toString());
        });  
    }; 

    static GetObservacoes = async (cliente, callback) => {        
        const url  = this.getHostServer()+this.getVersion()+'/clientes/observacoes/'+cliente;
        axios.get(url, this.getHeader())
        .then(function(response){            
            callback(response.data.status.toString(),response.data.retorno);
        })     
        .catch(function (error) {
            console.log(error);
            callback("-1",error.toString());
        });  
    };    
    
    static AddObservacao = async (cliente, payload, callback) => {
        let url  = this.getHostServer()+this.getVersion()+'/clientes/observacoes/'+cliente;
        axios.post(url,payload, this.getHeader())
        .then(function(response){                  
            callback(response.data.status.toString(),response.data.retorno);
        })     
        .catch(function (error) {
            console.log(error);
            callback("-1",error.toString());
        });  
    };

    static EditObservacao = async (cliente, id, payload, callback) => {
        let url  = this.getHostServer()+this.getVersion()+'/clientes/observacoes/'+cliente+"/"+id;
        axios.put(url,payload, this.getHeader())
        .then(function(response){                  
            callback(response.data.status.toString(),response.data.retorno);
        })     
        .catch(function (error) {
            console.log(error);
            callback("-1",error.toString());
        });  
    };  
    
    static GetCEP = async (cep, callback) => {        
        const url  = this.getHostServer()+this.getVersion()+'/utilitarios/cep/'+cep;
        axios.get(url, this.getHeader())
        .then(function(response){            
            callback(response.data.status.toString(),response.data.retorno);
        })     
        .catch(function (error) {
            console.log(error);
            callback("-1",error.toString());
        });  
    }; 

    static EditarEstabelecimento = async (payload, callback) => {        
        let url  = this.getHostServer()+this.getVersion()+'/estabelecimentos';
        axios.put(url,payload, this.getHeader())
        .then(function(response){ 
            callback(response.data);
        });   
    };

    static UploadLogoTipo = async (slug, logo, callback) => 
    {
        var bodyFormData = new FormData();
        bodyFormData.set('logo', logo);
        bodyFormData.set('slug',slug);
        axios(
            {
                method: 'post',
                url:  this.getHostServer()+this.getVersion()+'/estabelecimentos/logo',
                data: bodyFormData,
                headers: {'Content-Type': 'multipart/form-data', Authorization: this.getToken() }
                }
        )
        .then(function(response){            
            callback(response.data);
        });     
    };
}