import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import HttpsRedirect from './classes/HttpsRedirect';
// import { renderRoutes } from 'react-router-config';
//import './App.scss';
import { ColorProvider } from './ColorContext.js';
import Aprovador from './views/Aprovador/Aprovador';
import ClienteExterno from './views/ClienteExterno/ClienteExterno';
import EditaCliente from './views/EditaCliente/EditaCliente';
import EditarAnaminese from './views/EditarAnaminese/EditarAnaminese';
import NovaAnalise from './views/NovaAnalise/NovaAnalise';
import NovoCliente from './views/NovoCliente/NovoCliente';
import { FadingDiv } from './views/Base/FadingDiv/styles.js';

const loading = () => <FadingDiv>Aguarde...</FadingDiv>;

// Pages
const StartDirect = React.lazy(() => import('./views/StartDirect'));
const Login = React.lazy(() => import('./views/Pages/Login'));

class App extends Component {

  render() {
    return (
      <HttpsRedirect host={"gohair.app"}>
        <ColorProvider>
          <HashRouter>
              <React.Suspense fallback={loading()}>
                <Switch>
                  <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
                  <Route path="/:slug/NovoCliente" name="Novo Cliente" render={props => <NovoCliente {...props}/>} />
                  <Route path="/:slug/ClienteExterno" name="Novo Cliente" render={props => <ClienteExterno {...props}/>} />
                  <Route path="/:slug/EditaCliente/:cliente" name="Editar Cliente" render={props => <EditaCliente {...props}/>} />
                  <Route path="/:slug/NovaAnalise/:cliente/:id" name="Nova Analise" render={props => <NovaAnalise {...props}/>} />                
                  <Route path="/:slug/EditarAnaminese/:cliente" name="Editar Anaminese" render={props => <EditarAnaminese {...props}/>} />                                
                  <Route path="/Aprovador/:id" name="Nova Analise" render={props => <Aprovador {...props}/>} />       
                  <Route path="/" name="Home">
                    <StartDirect/> 
                    </Route>
                </Switch>
              </React.Suspense>
          </HashRouter>
        </ColorProvider>
      </HttpsRedirect>
    );
  }
}

export default App;
