import React, { createContext, useState } from "react";

const ColorContext = createContext();

export function ColorProvider({children}){
  const [colors, setColors] = useState({primary:"#8563A6",secondary:"#3F6CA6"});

  const setColor = (primary, secondary) => {
    setColors({primary:primary,secondary:secondary});
  }

  return (
    <ColorContext.Provider  value={{colors,setColor}}>{children}</ColorContext.Provider>
  )
}

export default ColorContext;